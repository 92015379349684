<template>
  <div class="form-item">
    <div class="project" @click="handleModel">
      <p v-if="projectArr.length === 0">请输入资金项目</p>
      <p v-else>
        <span v-for="(pro, i) in projectArr" :key="pro.id">
          {{ pro.fundProjectName }}({{ pro.fundProjectCode }})<strong
            @click="handleDel(i, pro)"
            class="del"
            >x</strong
          >
        </span>
      </p>
    </div>
    <div class="model" v-if="showModel">
      <div class="model-container">
        <div class="top">
          <h2>添加资金</h2>
          <span @click="closeModel" class="close">x</span>
        </div>
        <div class="model-main">
          <div class="side">
            <h3>基金分类导航</h3>
            <Tree :data="trees" @on-select-change="changeTree"></Tree>
          </div>
          <ul class="content">
            <div class="search">
              <Input placeholder="请输入基金名称" v-model="fundName"></Input>
              <Button @click="searchFilter">检索</Button>
            </div>
            <div class="checkbox-list">
              <CheckboxGroup
                v-for="v in pageList"
                :key="v.id"
                @on-change="handleCheck(v)"
                :value="fundArr"
              >
                <Checkbox :label="v.fundName"></Checkbox>
              </CheckboxGroup>
            </div>
            <Paging
              :total="total"
              :curPage="curPage"
              :limit="limit"
              :showPage="5"
              :showMini="'small'"
              @changePager="handlePager"
            ></Paging>
            <!-- 添加基金 -->
            <div class="addModel" v-if="showAddModel">
              <div class="addmodel-header">
                <h3>新增基金项目</h3>
                <span class="close" @click="showAddModel = false">x</span>
              </div>
              <FormItem
                label="项目号："
                :prop="addFund.num"
                :required="true"
                :show-message="false"
              >
                <Input v-model="addFund.num"></Input>
              </FormItem>
              <FormItem
                label="项目名称："
                :prop="addFund.name"
                :required="true"
                :show-message="false"
              >
                <Input v-model="addFund.name"></Input>
              </FormItem>
              <FormItem label="立项单位/部门：" prop="unit">
                <Input v-model="addFund.unit"></Input>
              </FormItem>
              <FormItem label="项目名称(英文)：" prop="enName">
                <Input v-model="addFund.enName"></Input>
              </FormItem>
              <FormItem label="项目说明：" prop="message">
                <Input v-model="addFund.message" type="textarea"></Input>
              </FormItem>
              <FormItem class="btn">
                <Button @click="cancel">取消</Button>
                <Button @click="submit(addFund)" v-throttleClick="2000">确认</Button>
              </FormItem>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paging from "@/components/paging";
export default {
  props: {
    // 资金项目数组
    projectArr: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Paging,
  },
  data() {
    return {
      testArr: [],
      testPro: {},
      fundArr: [], // 默认选中的数组
      fundName: "", // 基金名称
      treeList: [],
      pageList: [],
      showModel: false,
      showAddModel: false,
      addFund: {
        name: "",
        num: "",
        unit: "",
        enName: "",
        message: "",
        checkboxId: "",
      },
      count: 0,
      dto: {
        pageNo: 1,
        pageSize: 10,
      },
      // 分页
      curPage: 1,
      total: 0,
      limit: 0,
    };
  },
  created() {
    this.getFundTypeList();
  },
  mounted() {
    this.$bus.$on("mainDocument", (e) => {
      if (e.target.className === "model") {
        this.closeModel(); //关闭窗口
        this.showAddModel = false;
      }
    });
  },
  computed: {
    trees() {
      return this.treeList.map((item) => {
        return {
          title: item.typeName,
          parentId: item.id,
          children: item.list.map((v) => {
            return {
              title: v.typeName,
              id: v.id,
            };
          }),
        };
      });
    },
  },
  methods: {
    async handleDel(i, pro) {
      console.log("需要proId");
      console.log(pro);
      this.projectArr.splice(i, 1);
      this.pageList.forEach((item) => {
        if (item.id == pro.fundId) {
          this.fundArr.splice(this.fundArr.indexOf(item.fundName), 1);
        }
      });
    },
    handleCheck(v) {
      this.fundArr.push(v.fundName);
      this.testArr.push(v.fundName);
      this.addFund.checkboxId = v.id;
      this.showAddModel = true;
    },
    async submit(data) {
      if (!data.name || !data.num) {
        this.$Message.error("不能为空");
      } else {
        if (!/[a-zA-Z]+/g.test(data.enName) && data.enName) {
          this.$Message.error("只能是英文");
          return;
        }
        if (
          data.name.length > 50 ||
          data.num.length > 50 ||
          data.enName.length > 50 ||
          data.unit.length > 50 ||
          data.message.length > 800
        ) {
          this.$Message.error("字数已达上限");
          return;
        }
        const resp = await this.$apis.formServe.addFund({
          fundProjectName: data.name,
          fundProjectCode: data.num,
          fundId: data.checkboxId,
          fundProjectUnit: data.unit || null,
          fundProjectEnglish: data.enName || null,
          fundPreojectRemark: data.message || null,
        });
        if (typeof resp.data.data !== "string") {
          if (this.projectArr.length === 0) {
            this.projectArr.push(resp.data.data);
          } else {
            let flag = false;
            this.projectArr.forEach((item) => {
              if (resp.data.data.id === item.id) {
                console.log("chongfule");
                flag = true;
              }
            });
            if (!flag) {
              this.projectArr.push(resp.data.data);
            }
          }
        }

        this.$emit("handleFund", this.projectArr);
        this.cancel();
      }
    },
    searchFilter() {
      this.getPage(
        {
          ...this.dto,
          fundName: this.fundName,
        },
        1
      );
    },
    cancel() {
      this.addFund.num = "";
      this.addFund.name = "";
      this.addFund.enName = "";
      this.addFund.unit = "";
      this.addFund.message = "";
      this.showAddModel = false;
    },
    changeTree(item) {
      this.getPage(
        {
          ...this.dto,
          typeOneId: item[0].parentId,
          typeTwoId: item[0].id,
        },
        1
      );
    },
    async getPage(data, pro) {
      this.count = this.count + 1;
      console.log(this.count);
      const resp = await this.$apis.formServe.page(data);
      this.pageList = resp.data.list;
      this.total = +resp.data.total;
      this.limit = +resp.data.pageSize;
      console.log("getPage的pro参数");
      console.log(pro);
      console.log(this.projectArr);
      console.log(this.testArr);

      this.pageList.forEach((item) => {
        this.projectArr.forEach((v) => {
          if (item.id == v.fundId) {
            if (this.count == 1) {
              this.fundArr.push(item.fundName);
            }
          }
        });
      });
      console.log(this.fundArr);
    },
    async getFundTypeList() {
      const resp = await this.$apis.formServe.fundTypeList();
      this.treeList = resp.typeList;
    },
    handleModel(e) {
      if (e.target.className === "del") {
        return;
      }
      this.showModel = true;
      this.getPage(this.dto, 1);
    },
    closeModel() {
      this.showModel = false;
      this.showAddModel = false;
      console.log(this.fundArr);
      this.fundName = "";
    },
    handlePager(newPage) {
      this.curPage = newPage;
      this.getPage(
        {
          ...this.dto,
          pageNo: newPage,
        },
        1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-list ::v-deep .ivu-checkbox-group {
  height: 30px;
  line-height: 30px;
}
::v-deep .ivu-input-type-textarea {
  width: 200px;
}
::v-deep .ivu-form-item-label {
  width: 130px;
}
::v-deep .ivu-form-item {
  margin-bottom: 10px;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.form-item {
  width: 100%;
  min-height: 85px;
  padding: 25px 0;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
  .project {
    width: 100%;
    p {
      margin-left: 7px;
      .del {
        margin-right: 10px;
        cursor: pointer;
        padding: 10px;
        color: red;
      }
    }
  }
  .model {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 12;
    .model-container {
      width: 700px;
      height: 500px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
      color: #333;
      overflow: hidden;
      .close {
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-size: 20px;
        }
      }
      .model-main {
        display: flex;
        margin-top: 10px;
        height: 430px;
        .side {
          width: 200px;
          height: 100%;
          border-right: 1px solid #dcdcdc;
          overflow: auto;
          h3 {
            font-size: 14px;
            margin: 10px 0;
          }
        }
        .content {
          width: 450px;
          margin-left: 10px;
          .search {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &::v-deep button {
              margin-left: 10px;
              height: 38px;
            }
          }
        }
      }
      .addModel {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 350px;
        background-color: #fff;
        border: 1px solid;
        padding: 5px;
        overflow: hidden;
        z-index: 1;
        &::v-deep .ivu-input-type-text {
          width: 200px;
        }
        .addmodel-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h3 {
            font-size: 14px;
          }
        }
        .btn {
          margin-left: 200px;
          button {
            margin: 0 10px;
          }
        }
      }
    }
  }
}
</style>
