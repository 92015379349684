<template>
  <div class="form-content-container">
    <Form class="form-container">
      <div class="form-item">
        <div class="title">
          <Input
            placeholder="请输入标题(5-30字)"
            v-model.trim="formValidate.title"
            maxlength="30"
          />
        </div>
        <div class="number">{{ formValidate.title.length }}/30</div>
      </div>
      <FormAuther
        @autherInfo="handleAuther"
        @autherInfo1="handleAuther1"
        @autherInfo2="handleAuther2"
        :formValidate="formValidate"
      ></FormAuther>
      <div class="form-item">
        <Input
          placeholder="请输入关键词，多个关键词之间用英文逗号“,”隔开"
          v-model.trim="formValidate.keyword"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          type="textarea"
          :rows="4"
          placeholder="请输入摘要"
          v-model.trim="formValidate.artSummary"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入刊名"
          v-model.trim="formValidate.periodical"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <div class="year">
          <Input
            placeholder="请输入年份"
            type="number"
            maxlength="4"
            v-model.trim="formValidate.journalsYear"
          />
          <span class="xing">*</span>
        </div>
        <div class="center">
          <Input
            placeholder="请输入卷数"
            maxlength="4"
            v-model.trim="formValidate.journalsVolume"
          />
        </div>
        <div class="stage">
          <Input
            placeholder="请输入期数"
            maxlength="5"
            v-model.trim="formValidate.journalsTerm"
          />
          <span class="xing">*</span>
        </div>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入栏目"
          v-model.trim="formValidate.articleColumn"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入分类号"
          v-model.trim="formValidate.classifyNumber"
        />
        <span class="xing">*</span>
      </div>
      <CapitalModel
        @handleFund="handleFund"
        :projectArr="formValidate.tempFundIds"
      ></CapitalModel>
      <div class="form-item">
        <Input placeholder="请输入DOI" v-model.trim="formValidate.doi" />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入页数"
          type="number"
          v-model.trim="formValidate.pageNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <div class="year">
          <Input
            placeholder="请输入开始页码"
            type="number"
            v-model.trim="formValidate.startPageNumber"
          />
          <span class="xing">*</span>
        </div>
        <div class="center">
          <Input
            placeholder="请输入结束页码"
            type="number"
            v-model.trim="formValidate.endPageNumber"
          />
          <span class="xing">*</span>
        </div>
        <div class="stage">
          <Input
            placeholder="请输入转接页码"
            type="number"
            v-model.trim="formValidate.turnPageNumber"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="checkbox">
          <label for="">数据库收录：</label>
          <CheckboxGroup v-model.trim="formValidate.include">
            <Checkbox
              v-for="v in includeList"
              :key="v.id"
              :label="v.dbName"
            ></Checkbox>
          </CheckboxGroup>
        </div>
      </div>
      <div class="form-item" @click="handleFileDel">
        <div class="upload">
          <label for="">附件：</label>
          <Upload
            type="drag"
            :action="actionUrl"
            :format="['pdf']"
            :headers="{ token: token }"
            :on-success="handleSuccess"
            accept=".pdf"
            :show-upload-list="false"
          >
            <Button>上传</Button>
            <p style="margin-left: 7px" v-if="!formValidate.accessoryName">
              请上传pdf格式文件
            </p>
            <p style="margin-left: 7px" v-else>
              {{ formValidate.accessoryName }}
            </p>
          </Upload>
        </div>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <label for="" style="height: 60px; white-space: nowrap"
          >发布栏目：</label
        >
        <div class="checkbox" style="flex-direction: column">
          <CheckboxGroup v-model="columnNum">
            <Checkbox disabled :label="columnNum[0]"></Checkbox>
            <Checkbox disabled :label="columnNum[1]"></Checkbox>
          </CheckboxGroup>
          <Tree
            :data="treeList"
            show-checkbox
            @on-check-change="changeCheck"
          ></Tree>
        </div>
      </div>
    </Form>
    <div class="submit-container">
      <div class="btns">
        <Button
          :disabled="disableFlag"
          @click="handlePreview(0, '/paperpreview')"
          >预览</Button
        >
        <Button @click="handleSave(formValidate)" v-throttleClick="2000"
          >保存</Button
        >
        <Button @click="handleSubmit(formValidate)" v-throttleClick="2000"
          >发布</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import FormAuther from "../components/FormAuther.vue";
import CapitalModel from "../components/CapitalModel.vue";
import { mapState } from "vuex";
import preview from "@/mixins/preview.js";
export default {
  components: {
    FormAuther,
    CapitalModel,
  },
  mixins: [preview],
  created() {
    // 认证
    if (this.userInfo.user.customerType === 1) {
      this.isFlag = true;
    }
    this.projectAndColumnListByType({
      type: 2,
    });
    this.getIncludeList(); // 收录
    if (this.$route.query.id) {
      this.getEcho(this.formValidate);
      this.disableFlag = false;
    }
  },
  data() {
    return {
      checked: false,
      isFlag: false,
      tempArr: [],
      treeList: [], //发布栏目
      includeList: [],
      actionUrl: `${this.$global.url1}/zw-public/web/common/upload`, // 接口路径
      formValidate: {
        firstAuther: "",
        firstUnit: "",
        firstUnitId: "",
        communicationAuthor: "",
        communicationAuthorUnit: "",
        communicationAuthorUnitId: "",
        title: "",
        keyword: "",
        artSummary: "",
        periodical: "",
        journalsYear: "",
        journalsVolume: "",
        journalsTerm: "",
        articleColumn: "",
        classifyNumber: "",
        doi: "",
        interest: [], // 栏目
        include: [], // 数据库收录
        autherList: [
          {
            authorName: "",
            authorUnit: "",
            showSelect1: false,
            unitDisabled1: true,
            authorUnitId: "",
            authorId: "",
          },
        ],
        articleAuthorList: [], // 其他作者
        startPageNumber: "", //开始页码
        endPageNumber: "", //结束页码
        turnPageNumber: "", //转接页码
        pageNumber: "", //页数
        fundIds: [], // 资金项目
        tempFundIds: [], // 资金项目
        filePath: "", // 文件路径
        accessoryName: "", // 文件名称
        synchronizedStatus: false,
      },
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    columnNum() {
      let arr = [];
      if (sessionStorage.getItem("isSon") === "false") {
        if (this.userInfo.user.customerType === 4) {
          arr = ["百科号"];
        } else if (this.userInfo.user.customerType === 5) {
          arr = ["机构号"];
        } else if (
          this.userInfo.user.customerType === 1 ||
          this.userInfo.user.customerType === 2
        ) {
          arr = ["百家号"];
        }
      } else {
        arr = ["百家号", "学者空间"];
      }
      return arr;
    },
  },
  methods: {
    verification(formValidate) {
      if (
        formValidate.journalsYear <= 1970 ||
        formValidate.journalsYear > new Date().getFullYear()
      ) {
        this.$Message.error("年份格式不正确");
        return false;
      }
      if (this.formValidate.title.length < 5) {
        this.$Message.error("标题不能少于五个字");
        return false;
      }
      if (!/[a-zA-Z0-9]+/g.test(this.formValidate.classifyNumber)) {
        this.$Message.error("只能是数字和字母");
        return false;
      }
      // 分隔
      if (/^,|[，。？！.?!]+|(,,)+|,$/.test(formValidate.keyword)) {
        this.$Message.error("多个关键词，只能以英文逗号分隔");
        return false;
      } else {
        let arr = formValidate.keyword.split(",");
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == "") {
            this.$Message.error("关键词中存在多余英文逗号");
            return false;
          }
        }
      }
      if (formValidate.artSummary.length > 2500) {
        this.$Message.error("内容简介超出限制");
        return false;
      }
      return true;
    },
    getTrees() {
      this.treeList = this.treeList.map((item) => {
        return {
          title: item.projectName + ' ——',
          expand: true,
          menuIds: item.projectId,
          checked: false,
          children:
            item.columns &&
            item.columns.map((v) => {
              return {
                title: v.columnName,
                menuIds: item.projectId,
                id: v.id,
                checked: false,
              };
            }),
        };
      });
    },
    // 回显
    async getEcho(f) {
      const resp = await this.$apis.formServe.getEcho({
        articleId: this.$route.query.id,
        articleType: 0,
      });
      console.log(resp);
      // 发布栏目
      resp.data.articlePushList.forEach((itemL) => {
        this.treeList.forEach((itemT) => {
          if (
            itemT.children &&
            itemT.children.length > 0 &&
            itemL.projectId !== undefined
          ) {
            itemT.children.forEach((itemC) => {
              if (itemC.id == itemL.channelId1) {
                itemC["checked"] = true;
              }
            });
          } else {
            if (itemT.menuIds == itemL.projectId) {
              itemT["checked"] = true;
            }
          }
        });
      });

      const r = resp.data.data;
      f.title = r.title;
      f.keyword = r.keyword;
      f.artSummary = r.artSummary;
      f.periodical = r.periodical;
      f.journalsYear = r.journalsYear;
      f.journalsVolume = r.journalsVolume;
      f.journalsTerm = r.journalsTerm;
      f.articleColumn = r.articleColumn;
      f.classifyNumber = r.classifyNumber;
      f.doi = r.doi;
      f.include = r.dataStyle.split(",");
      f.pageNumber = r.pageNumber;
      f.filePath = r.filePath; // 文件路径
      f.accessoryName = r.accessoryName; // 文件名称
      f.firstAuther = r.firstAuthor;
      f.firstUnit = r.firstAuthorUnit;
      f.communicationAuthor = r.communicationAuthor;
      f.communicationAuthorUnit = r.communicationAuthorUnit;
      let pageStr = r.sizeNumber.split(",");
      let pageNumStr = pageStr[0].split("-");
      f.startPageNumber = pageNumStr[0]; //开始页码
      f.endPageNumber = pageNumStr[1]; //结束页码
      f.turnPageNumber = pageStr[1]; //转接页码
      f.tempFundIds = r.fundList; // 资金
      // 其他作者
      this.tempAutherList = r.articleAuthorList;
      for (var i = 0; i < r.articleAuthorList.length - 1; i++) {
        f.autherList.push({
          authorName: "",
          authorUnit: "",
          showSelect1: false,
          unitDisabled1: true,
          authorUnitId: "",
          authorId: "",
        });
      }
      r.articleAuthorList.forEach((vv, index) => {
        f.autherList[index].authorName = vv.authorName;
        f.autherList[index].authorUnit = vv.authorUnit;
        f.autherList[index].authorId = vv.authorId;
        f.autherList[index].authorUnitId = vv.authorUnitId;
      });
      f.synchronizedStatus = r.synchronizedStatus == 1 ? true : false;
    },
    async handleSave(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      if (this.tempArr.length === 0) {
        this.treeList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            item.children.forEach((v) => {
              if (v.checked) {
                this.tempArr.push(v);
              } else {
                this.tempArr = [];
              }
            });
          } else {
            if (item.checked) {
              this.tempArr.push(item);
            } else {
              this.tempArr = [];
            }
          }
        });
      }
      // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return false;
      }
      if (!this.verification(formValidate)) {
        return false;
      }
      formValidate.interest = [];
      this.tempArr.forEach((e) => {
        if (e.children && e.children.length > 0) {
          return false;
        }
        formValidate.interest.push({
          projectId: e.menuIds || null,
          channelId1: e.id || null,
        });
      });
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 1,
        articleType: 0,
        type: 0,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        title: formValidate.title,
        keyword: formValidate.keyword,
        artSummary: formValidate.artSummary,
        periodical: formValidate.periodical,
        journalsYear: formValidate.journalsYear,
        journalsVolume:
          formValidate.journalsVolume === ""
            ? null
            : formValidate.journalsVolume,
        journalsTerm: formValidate.journalsTerm,
        articleColumn: formValidate.articleColumn,
        classifyNumber: formValidate.classifyNumber,
        doi: formValidate.doi,
        dataStyle: formValidate.include.join(),
        pageNumber: formValidate.pageNumber,
        sizeNumber: `${formValidate.startPageNumber}-${
          formValidate.endPageNumber
        }${formValidate.turnPageNumber ? "," : ""}${
          formValidate.turnPageNumber
        }`,
        fundList:
          formValidate.fundIds.length > 0
            ? formValidate.fundIds
            : formValidate.tempFundIds,
        filePath: formValidate.filePath, // 文件路径
        accessoryName: formValidate.accessoryName, // 文件名称
        articlePushList: this.tempArr.length > 0 ? formValidate.interest : [],
        articleAuthorList:
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList, // 其他作者
        firstAuthor: formValidate.firstAuther, // 第一作者
        firstAuthorUnit: formValidate.firstUnit,
        firstAuthorUnitId: formValidate.firstUnitId,
        firstAuthorId: formValidate.firstAutherId,
        communicationAuthor: formValidate.communicationAuthor, // 通讯作者
        communicationAuthorUnit: formValidate.communicationAuthorUnit,
        communicationAuthorUnitId: formValidate.communicationAuthorUnitId,
        communicationAuthorId: formValidate.communicationAuthorId,
        articleId: this.$route.query.id || this.previewData.articleId,
        synchronizedStatus: formValidate.synchronizedStatus === true ? 1 : 0,
      });
      // this.clearField(); // 清空
      if (resp.status === 200) {
        this.previewData = resp.data.data;
        this.$Message.success(resp.data.resultDesc);
      } else {
        this.$Message.error(resp.data.resultDesc);
      }
      this.disableFlag = false;
      return true;
    },
    async handleSubmit(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      if (this.tempArr.length === 0) {
        this.treeList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            item.children.forEach((v) => {
              if (v.checked) {
                this.tempArr.push(v);
              }
            });
          } else {
            if (item.checked) {
              this.tempArr.push(item);
            }
          }
        });
      }
      // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return;
      }
      if (!this.verification(formValidate)) {
        return;
      }
      formValidate.interest = [];
      this.tempArr.forEach((e) => {
        if (e.children && e.children.length > 0) {
          return;
        }
        formValidate.interest.push({
          projectId: e.menuIds || null,
          channelId1: e.id || null,
        });
      });
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 0,
        articleType: 0,
        type: 0,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        title: formValidate.title,
        keyword: formValidate.keyword,
        artSummary: formValidate.artSummary,
        periodical: formValidate.periodical,
        journalsYear: formValidate.journalsYear,
        journalsVolume:
          formValidate.journalsVolume === ""
            ? null
            : formValidate.journalsVolume,
        journalsTerm: formValidate.journalsTerm,
        articleColumn: formValidate.articleColumn,
        classifyNumber: formValidate.classifyNumber,
        doi: formValidate.doi,
        dataStyle: formValidate.include.join(),
        pageNumber: formValidate.pageNumber,
        sizeNumber: `${formValidate.startPageNumber}-${
          formValidate.endPageNumber
        }${formValidate.turnPageNumber ? "," : ""}${
          formValidate.turnPageNumber
        }`,
        fundList:
          formValidate.fundIds.length > 0
            ? formValidate.fundIds
            : formValidate.tempFundIds,
        filePath: formValidate.filePath, // 文件路径
        accessoryName: formValidate.accessoryName, // 文件名称
        articlePushList: formValidate.interest,
        articleAuthorList:
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList, // 其他作者
        firstAuthor: formValidate.firstAuther, // 第一作者
        firstAuthorUnit: formValidate.firstUnit,
        firstAuthorUnitId: formValidate.firstUnitId,
        firstAuthorId: formValidate.firstAutherId,
        communicationAuthor: formValidate.communicationAuthor, // 通讯作者
        communicationAuthorUnit: formValidate.communicationAuthorUnit,
        communicationAuthorUnitId: formValidate.communicationAuthorUnitId,
        communicationAuthorId: formValidate.communicationAuthorId,
        articleId: this.$route.query.id || this.previewData.articleId,
        synchronizedStatus: formValidate.synchronizedStatus === true ? 1 : 0,
      });
      this.clearField(); // 清空
      if (resp.status === 200) {
        this.$Message.success(resp.data.resultDesc);
      }
      this.$router.push("/user"); //
      console.log(resp);
    },
    // 文件删除处理
    handleFileDel(e) {
      if (
        e.target.className ===
        "ivu-icon ivu-icon-ios-close ivu-upload-list-remove"
      ) {
        this.formValidate.filePath = ""; // 文件路径
        this.formValidate.accessoryName = ""; // 文件名称
      }
    },
    handleSuccess(res, file) {
      const MaxSize = 1024 * 1024 * 20;
      if (file.size > MaxSize) {
        this.$Message.error("文件太大，请重新上传");
      }
      this.formValidate.filePath = res.content.url;
      this.formValidate.accessoryName = file.name;
    },
    // 资金项目
    handleFund(fund) {
      this.formValidate.fundIds = fund;
    },
    handleAuther2(name, unit, orgId, userId) {
      this.formValidate.communicationAuthor = name;
      this.formValidate.communicationAuthorUnit = unit;
      this.formValidate.communicationAuthorUnitId = orgId;
      this.formValidate.communicationAuthorId = userId;
    },
    handleAuther1(list) {
      console.log(list);
      this.formValidate.articleAuthorList = list;
    },
    handleAuther(name, unit, orgId, userId) {
      this.formValidate.firstAuther = name;
      this.formValidate.firstUnit = unit;
      this.formValidate.firstUnitId = orgId;
      this.formValidate.firstAutherId = userId;
    },
    // 数据库收录
    async getIncludeList() {
      const resp = await this.$apis.formServe.getIncludeList();
      this.includeList = resp.dbList;
    },
    // 发布栏目
    async projectAndColumnListByType(data) {
      const resp = await this.$apis.formServe.projectAndColumnListByType(data);
      this.treeList.push(resp.data[0]);
      this.getTrees();
    },
    changeCheck(item) {
      this.tempArr = item;
    },
    clearField() {
      // 清空
      this.formValidate.firstAuther = "";
      this.formValidate.firstUnit = "";
      this.formValidate.firstUnitId = "";
      this.formValidate.communicationAuthor = "";
      this.formValidate.communicationAuthorUnit = "";
      this.formValidate.communicationAuthorUnitId = "";
      this.formValidate.title = "";
      this.formValidate.keyword = "";
      this.formValidate.artSummary = "";
      this.formValidate.periodical = "";
      this.formValidate.journalsYear = "";
      this.formValidate.journalsVolume = "";
      this.formValidate.journalsTerm = "";
      this.formValidate.articleColumn = "";
      this.formValidate.classifyNumber = "";
      this.formValidate.doi = "";
      this.formValidate.include = null; // 数据库收录
      this.formValidate.articleAuthorList = []; // 其他作者
      this.formValidate.startPageNumber = ""; //开始页码
      this.formValidate.endPageNumber = ""; //结束页码
      this.formValidate.turnPageNumber = ""; //转接页码
      this.formValidate.pageNumber = ""; //页数
      this.formValidate.fundIds = []; // 资金项目
      this.formValidate.filePath = ""; // 文件路径
      this.formValidate.accessoryName = ""; // 文件名称
      this.formValidate.synchronizedStatus = false;
    },
    isEmpty(f) {
      if (
        f.title === "" &&
        f.firstAuther === "" &&
        f.firstUnit === "" &&
        f.keyword === "" &&
        f.artSummary === "" &&
        f.periodical === "" &&
        f.journalsYear === "" &&
        f.journalsTerm === "" &&
        f.articleColumn === "" &&
        f.classifyNumber === "" &&
        f.doi === "" &&
        f.startPageNumber === "" &&
        f.endPageNumber === "" &&
        f.accessoryName === "" &&
        f.pageNumber === ""
      ) {
        this.$Message.error("不能为空");
        return true;
      } else if (f.title === "") {
        this.$Message.error("标题不能为空");
        return true;
      } else if (f.firstAuther === "") {
        this.$Message.error("第一作者不能为空");
        return true;
      } else if (f.firstUnit === "") {
        this.$Message.error("第一作者单位不能为空");
        return true;
      } else if (f.keyword === "") {
        this.$Message.error("关键词不能为空");
        return true;
      } else if (f.artSummary === "") {
        this.$Message.error("摘要不能为空");
        return true;
      } else if (f.periodical === "") {
        this.$Message.error("刊名不能为空");
        return true;
      } else if (f.journalsYear === "") {
        this.$Message.error("年份不能为空");
        return true;
      } else if (f.journalsTerm === "") {
        this.$Message.error("期数不能为空");
        return true;
      } else if (f.articleColumn === "") {
        this.$Message.error("栏目不能为空");
        return true;
      } else if (f.classifyNumber === "") {
        this.$Message.error("分类号不能为空");
        return true;
      } else if (f.doi === "") {
        this.$Message.error("DOI不能为空");
        return true;
      } else if (f.startPageNumber === "") {
        this.$Message.error("开始页码不能为空");
        return true;
      } else if (f.endPageNumber === "") {
        this.$Message.error("结束页码不能为空");
        return true;
      } else if (f.accessoryName === "") {
        this.$Message.error("附件不能为空");
        return true;
      } else if (f.pageNumber === "") {
        this.$Message.error("页数不能为空");
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-tree .ivu-checkbox-wrapper {
  margin: 0;
}
::v-deep .ivu-tree ul li {
  margin: 0;
  height:37px;
}
::v-deep .ivu-tree  li ul {
  padding:0;
}
::v-deep .ivu-tree-children li {
  display: flex;
  align-items: center;
}
::v-deep .ivu-tree-children li .ivu-tree-arrow {
  display: none;
}
// ::v-deep .ivu-tree-children li .ivu-tree-children li {
//   margin: 30px 40px 0 -60px;
// }
// ::v-deep .ivu-tree-children li .ivu-tree-title {
//   width: 100px;
// }
::v-deep .ivu-radio-group {
  white-space: nowrap;
}
.form-content-container {
  margin: 38px auto 0;
  position: relative;
}
.ivu-input-wrapper ::v-deep input {
  border: none;
  background-color: #fff;
  height: 38px;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.ivu-upload ::v-deep .ivu-upload-drag {
  border: none;
  display: flex;
  align-items: center;
}
::v-deep .ivu-upload {
  height: 38px;
  display: flex;
  align-items: center;
}
.ivu-input-wrapper ::v-deep textarea {
  border: none;
}
.form-container {
  width: 1200px;
  min-height: 1228px;
  background-color: #fff;
  padding: 60px 100px 0;
  margin: 4px auto 30px;
  box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
  .form-item {
    width: 100%;
    min-height: 85px;
    padding: 25px 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }
    .title {
      width: 900px;
      height: 38px;
      line-height: 38px;
      position: relative;
      ::v-deep input {
        font-size: 36px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .xing {
      width: 30px;
      color: red;
      text-align: center;
    }
    .left,
    .right {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .left {
      border-right: 1px solid #dcdcdc;
      width: 355px;
    }
    .right {
      margin-left: 10px;
      width: 642px;
    }
    .year,
    .center,
    .stage {
      display: flex;
      align-items: center;
    }
    .year {
      width: 299px;
      border-right: 1px solid #dcdcdc;
    }
    .center {
      width: 323px;
      border-right: 1px solid #dcdcdc;
    }
    .stage {
      width: 374px;
    }
    .checkbox {
      width: 970px;
      display: flex;
      padding-left: 7px;
      label {
        margin-right: 20px;
      }
    }
    .upload {
      display: flex;
      padding-left: 7px;
      align-items: center;
      label {
        margin-right: 20px;
      }
    }
  }
}
.submit-container {
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 70px;
  .btns {
    margin-left: 800px;
  }
  button {
    width: 90px;
    height: 36px;
    margin: 0 10px;
    border: 1px dashed #333333;
    border-radius: 8px;
    &:last-child {
      background: #00a4ff;
      border: 1px solid #00a4ff;
      border-radius: 8px;
      color: #fff;
    }
  }
}
</style>
